import $ from 'jquery';
// import 'bootstrap';
// import 'slick-carousel';
import 'lazysizes';

// lazy load bg image
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import common from '../../../../../app_bipa_mobile/cartridge/static/default/js/bipa/common/common';
import miniCart from '../../../../../app_bipa_mobile/cartridge/static/default/js/bipa/mobile/common/minicart';
import { initHover } from '../../../../../app_bipa_mobile/cartridge/static/default/js/bipa/common/minicart';
// import {Constants} from '../../../../../app_bipa_mobile/cartridge/static/default/js/bipa/common/Constants.json';

// Default CSS
import '../../../../../app_bipa/cartridge/static/default/sass/common.scss';

// page designer components CSS
// Page Designer Components
import '../scss/carousel.scss';
import '../scss/centerCarousel.scss';
import '../scss/gridcomponentlayout.scss';
import '../scss/headlinebanner.scss';
import '../scss/headlinewithtext.scss';
import '../scss/imagetext.scss';
import '../scss/mainbanner.scss';
import '../scss/countdownbanner.scss';
import '../scss/pdgeneral.scss';
import '../scss/productgrid.scss';
import '../scss/promotile.scss';
import '../scss/richtext.scss';
import '../scss/roundtile.scss';
import '../scss/slick.scss';
import '../scss/textbox.scss';
import '../scss/imagecard.scss';
import '../scss/liveVideo.scss';
import '../scss/youtubeVideo.scss';
import '../scss/grid.scss';
import '../scss/bannerCarousel.scss';
import '../scss/banner.scss';
import '../scss/bannerCard.scss';
import '../scss/accordion.scss';

// page designer components JS
// import '../js/lightbox'; lazy loaded
import '../js/carousel';
import '../js/pd_productgrid';
import '../js/pd_youtube';
import '../js/pd_countdownbanner';
import '../js/liveVideo';
import '../js/promotile';
import '../js/einstein-recommendations';

// Share jquery
window.jQuery = $;
window.$ = $;

common();
initHover();
miniCart.init();
